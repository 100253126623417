import React from 'react';


export const ru = {
    main: {
        slogan: 'ИИ в атомной отрасли: \n повышаем производительность труда, \n ускоряем выполнение работ'
    },
    aboutSolut: {
        preDescription: 'Мы ИТ-компания, созданная крупнейшими игроками с экспертизой в IT и строительстве. Компания реализует ИТ-услуги полного цикла: ИТ-разработка собственных продуктов, in-house создание ИИ, внедрение решений на объекты клиентов собственными силами',
        description: 'Основным направлением деятельности является:',
        point1: 'предоставление лицензии на продукт собственной разработки "Система мониторинга физического труда SOLUT RA"',
        point2: 'внедрение продукта SOLUT RA на предприятиях и промплощадках клиентов: \n' +
            '- проведение работ по настройке ПО и сопутствующего оборудования \n' +
            '- инструктажи и методические указания по использованию ПО \n' +
            '- консультирование служб клиента для выработки максимально эффективного сценария использования ПО',
    },
    sphere: {
        heading: 'SOLUT ATOMIC ДЛЯ АТОМНОЙ ОТРАСЛИ',
        sphere1: {
            title: 'Девелопмент и строительство',
            description: 'Внедрение SOLUT ATOMIC при строительстве объектов атомной отрасли значительно повышает эффективность работы как собственных сотрудников, так и подрядчиков.\n' +
                'Увеличение производительности на 30% экономит миллионы на трудовых расходах и делает наших клиентов более конкурентоспособными при участии в тендерах.\n' +
                'Оптимизация численности рабочих и ускорение сроков выполнения проектов обеспечивают строгий контроль и высокую эффективность на каждом этапе строительства'
        },
        sphere2: {
            title: '',
            description: '',
        },
        sphere3: {
            title: '',
            description: '' ,
        } ,
        sphere4: {
            title: '',
            description: '',
        }
    },
    howItsWork: {
        heading: 'КАК ЭТО РАБОТАЕТ?',
        description: '',
        description1: 'В начале смены каждый работник получает умные часы на пункте выдачи, пропускном пункте или на складе. ПО SOLUT RA собирает данные о движениях рук рабочего и передает на сервер',
        description2: 'ИИ SOLUT RA собственной разработки распознает характер действий по движению рук человека. Генерируются отчеты о количестве продуктивного времени, простоев и перемещений для каждого сотрудника',
        description3: 'Наша методология и ежедневная аналитика обеспечивают реальный рост производительности'
    },
    technologies: {
        heading: 'ИНТЕРФЕЙС И СТЕК ТЕХНОЛОГИЙ',
        description: 'При разработке и функционировании ПО SOLUT RA используются только решения с открытым исходным кодом (open source лицензии MIT, BSD, Apache)',
        programmingLanguagesLabel: 'Языки программирования',
        programmingLanguages: 'Java (OpenJDK), TypeScript, JavaScript',
        frameworksLabel: 'Фреймворки',
        frameworks: 'Java 17, Gradle 6.3, Spring Framework, Hibernate, Angular 16',
        librariesLabel: 'Библиотеки',
        libraries: 'org.springframework, com.github.lookfirst, commons-io, com.google.guava, javax.servlet, net.logstash.logback, org.apache.poi, com.google.code.gson, com.google.api, org.postgresql, Angular Material, Three.js, Chart.js, NgRx, RxJs',
    },
    solveProblem: {
        heading: 'КАКИЕ ПРОБЛЕМЫ МЫ РЕШАЕМ?',
        description1: 'Длительные простои в течение рабочих смен, несоблюдение графиков перерывов',
        description2: 'Выявление причин простоя',
        description3: 'Нарушение технических процессов (последовательности действий)'
    },
    whatWeDo: {
        heading: 'SOLUT RA',
        description: 'Наш флагманский продукт - "Система мониторинга физического труда SOLUT RA" - это уникальное решение на базе ИИ, позволяющее улучшать эффективность сотрудников в атомной отрасли',
        prosLabel: 'Основные преимущества системы:',
        pros1: 'Доказанное повышение производительности от 20%',
        pros2: 'Быстрое и простое внедрение',
        pros3: 'Ценность и для работодателя, и для работников',
        descriptionLabel: 'Что мы делаем:',
        description1: 'Повышаем производительность труда за счет снижения стоимости рабочих часов и простоев',
        description2: 'Выявляем причины простоя и решаем операционные проблемы',
        description3: 'Внедряем практику справедливой оплаты труда работников и улучшаем их качество жизни',
        description4: 'В составе продукта SOLUT RA: программное обеспечение собственной разработки, носимые устройства, внедрение на площадке, методология пользования продуктом',
        postDescription: 'SOLUT RA зарегистрировано в Едином реестре российских программ для ЭВМ (Реестровая запись №24776 от 15.11.2024)'
    },
    otherSystems: {
        heading: 'ПОЧЕМУ ДРУГИЕ ПОДХОДЫ \n НЕ ПРИНОСЯТ ОЖИДАЕМЫХ РЕЗУЛЬТАТОВ?',
        description: 'SOLUT – самая эффективная и надежная система мониторинга производительности труда на сегодняшний день. В отличие от других технологий и практик, мы проводим точные измерения в любых условиях, для любой отрасли и без дополнительной инфраструктуры или участия человека',
        system1: {
            title: 'Методы НАБЛЮДЕНИЯ',
            description: 'требуют участия человека и не всегда надежны (человеческий фактор)'
        },
        system2: {
            title: 'СИСТЕМЫ ВИДЕОНАБЛЮДЕНИЯ,',
            description: 'связанные с конкретным местоположением, требуют постоянного обслуживания и обширной инфраструктуры'
        },
        system3: {
            title: 'ОТСЛЕЖИВАНИЕ МЕСТОПОЛОЖЕНИЯ',
            description: 'позволяет узнать, где находятся сотрудники, но не обеспечивает измерение производительности и требует значительных затрат на инфраструктуру'
        }
    },
    realization: {
        heading: 'ВНЕДРЕНИЕ',
        description: '',
        step1_1: 'Свяжитесь с нами для обсуждения проекта',
        step1_2: 'и заключения контракта',
        step2_1: 'Через 2 недели наши специалисты',
        step2_2: 'приедут к вам и мы',
        step2_3: 'запустим систему',
        step3_1: 'Получайте больше прибыли',
        step3_2: 'и опережайте конкурентов,',
        step3_3: 'и стройте лучший коллектив',
    },
    charts: {
        heading: 'ЧТО ВЫ ПОЛУЧАЕТЕ?',
        chart1: {
            title: 'Обзор рабочего дня',
            description: 'Работодатель получает лицензию SOLUT RA и полностью внедренный продукт, значительно повышающий производительность',
            additionalDescription: 'Каждый работник и бригадир получают ежедневный отчет о своей продуктивности за смену, включая все периоды работы, перемещений и простоя. Эти отчеты помогают анализировать рабочие ритмы, выявлять потери времени и быстро улучшать процессы',
        },
        chart2: {
            title: 'Графики сравнения',
            description: 'Отчеты включают динамику производительности групп, графики индивидуальной производительности и распределение показателей в разрезе проектов, подрядчиков, подразделений, специальностей и тд.',
        },
        chart3: {
            title: '',
            description: '',
        }
    },
    payback: {
        headingCost: 'СТОИМОСТЬ',
        headingPayback: 'ВОЗВРАТ ИНВЕСТИЦИЙ',
        point1: {
            number: '250',
            description: 'ОКУПАЕМОСТЬ \n ИНВЕСТИЦИЙ',
        },
        point2: {
            number: '30',
            description: 'ПОВЫШЕНИЕ \n ПРОИЗВОДИТЕЛЬНОСТИ',
        },
        point3: {
            number: '23',
            description: 'Годовая стоимость* лицензии базового ПО SOLUT RA на 1000 рабочих (НДС не облагается**)',
            additional: '- Минимальный объем контрактации: лицензия на 1 год на 1000 рабочих\n' +
                '- При контрактации более чем на 5000 рабочих предусмотрена скидочная программа'
        },
        point4: {
            number: '5',
            description: 'Стоимость работ по внедрению на одной площадке (НДС сверху)'
        },
        point5: {
            number: '22',
            description: 'Стоимость аппаратного обеспечения для внедрения на 1000 рабочих на одной площадке (НДС сверху)'
        },
        ps1: '*не является офертой',
        ps2: '**НДС не облагается на основании пп. 26, п. 2, ст. 149 НК РФ – программа для ЭВМ «Система мониторинга физического труда SOLUT RA» зарегистрирована в Едином реестре российских программ для ЭВМ (Реестровая запись №24776 от 15.11.2024)',
    },
    projects: {
        heading: 'РЕАЛИЗОВАННЫЕ ПРОЕКТЫ',
        project1: {
            name: 'АЭС "Аккую"',
            description: 'Внедрение ПО SOLUT RA для контроля хода строительных работ первой АЭС в Турции (≈ $25 млрд.):\n' +
                '− до 6000 рабочих в мониторинге\n' +
                '− 49 специальностей',
            resultsLabel: 'Результаты:',
            results: '− рост выработки строителей более чем на 25%\n' +
                '− пролонгация контракта на 3-й год \n' +
                '− интеграция системы контроля и управления доступом заказчика',
            image: '/images/build.png',
        },
        project2: {
            name: 'АЭС "Эль-Дабаа"',
            description: 'Внедрение ПО SOLUT RA для контроля  хода строительных работ первой АЭС в Египте (≈ $30 млрд.):\n' +
                '− до 500 рабочих в мониторинге\n' +
                '− 7 специальностей',
            resultsLabel: 'Результаты:',
            results: '− рост выработки строителей более чем на 10%\n' +
                '− масштабирование проекта (новый контракт на +600 рабочих)\n' +
                '− заказная доработка ПО под потребности заказчика',
            image: '/images/project2.jpg',
        }
    },
    reviews: {
        heading: 'ОТЗЫВЫ',
        review1: {
            project: 'АО "Концерн ТИТАН-2"',
            author: 'Титаренко С. И, Заместитель директора по цифровой трансформации, АО "Концерн ТИТАН-2"',
            description: '«Внедрение системы SOLUT RA стало для нашей компании значимым шагом в повышении прозрачности и эффективности строительных процессов. Мы отметили не только увеличение производительности, но и множество других преимуществ.\n' +
                '\n' +
                'Прежде всего, система позволила значительно улучшить соблюдение сроков и ускорить темпы строительства. Это стало возможным благодаря оперативной идентификации и устранению причин системных простоев. Теперь мы можем получать детализированные данные о ежедневной активности персонала, что помогает точнее планировать работу и минимизировать потери времени.\n' +
                '\n' +
                'Контроль над деятельностью подрядчиков также вышел на новый уровень.  многие подрядчики уже согласились использовать эту систему в своей работе, что улучшает прозрачность и качество их труда.\n' +
                '\n' +
                'На практике мы убедились, что использование SOLUT RA работает и приносит значительные улучшения в организацию труда.»',
        },
        review2: {
            project: 'TSM Enerji',
            author: 'Купрещенко М.И., Руководитель проекта, TSM Enerji',
            description: '«Система SOLUT RA дала нам возможность внедрить эффективную систему мотивации для сотрудников. Наиболее активные и результативные работники теперь получают премии, что положительно влияет на их вовлечённость и стремление к достижению целей.\n' +
                '\n' +
                'Умные часы, интегрированные с системой, стали незаменимым инструментом для руководителей всех уровней. Система помогает инженерам, прорабам и бригадирам следить за прогрессом своих команд и оперативно принимать решения на основе объективных данных.\n' +
                '\n' +
                'В целом, ПО SOLUT RA зарекомендовало себя как удобный и надежный инструмент, который не только оптимизирует рабочие процессы, но и создаёт новую культуру производительности и ответственности.»',
        },
    },
    map: {
        heading: '',
        dotDescription1: '',
        dotDescription2: '',
        dotDescription3: '',
        point1: {
            number: '',
            description: '',
        }
    },
    results: {
        heading: '',
        result1: {
            title: '',
            description: ''
        },
        result2: {
            title: '',
            description: '',
        },
        result3: {
            title: '',
            description: '',
        }
    },
    contacts: {
        heading: 'СВЯЖИТЕСЬ \n С НАМИ',
        card1: {
            name: 'МАРИЯ \n ХАЛИЗОВА',
            lang: '',
            phone: '+7 (917) 832-20-02',
            country: '',
            email: 'info@solut-atomic.ru',
            contactNumber: '79178322002',
            button: 'Связаться'
        },
    },
    form: {
        heading: '',
        description: '',
        form: {
            name: '',
            contact: '',
            question: '',
            button: ''
        }
    },
    documents: {
        link: 'Документация',
        heading: 'Документация разработчика',
        functionalDescription: {
            name: 'Функциональные характеристики SOLUT RA',
            link: '/docs/functional-description.pdf '
        },
        setupManual: {
            name: 'Руководство по установке системы SOLUT RA',
            link: '/docs/setup-manual.pdf'
        },
        userManual: {
            name: 'Руководство пользователя SOLUT RA',
            link: '/docs/user-manual.pdf'
        },
        adminManual: {
            name: 'Руководство администратора SOLUT RA',
            link: '/docs/admin-manual.pdf '
        },
        watchManual: {
            name: 'Руководство пользователя для работы с модулем для носимых устройств SOLUT RA',
            link: '/docs/watch-manual.pdf'
        },
    },
    footer: {
        projectInfo: 'Общество с ограниченной ответственностью «СОЛЮТ-Атомик»,\n ИНН 7751279260'
    }
}

export const gb = {
    main: {
        slogan: 'WE INCREASE LABOR PRODUCTIVITY'
    },
    aboutSolut: {
        description: 'SOLUT increases labor productivity by about 30% by answering the most fundamental questions in every labor-intensive industry.\n' +
            '\n How much time laborers actually work during the shift?\n' +
            ' How much time they are idle? \n' +
            ' How much time is spent walking?\n' +
            ' What stops them from being productive?\n' +
            '\n' +
            'And we do this for every trade and every laborer, every day\n'
    },
    sphere: {
        heading: 'SOLUT IS FOR EVERY INDUSTRY',
        sphere1: {
            title: 'Construction and property development',
            description: `Contractors get unimaginable productivity levels with SOLUT. In a typical project, 30% higher productivity saves millions on labor costs, making projects more profitable. Increased labor productivity also makes contractors more competitive in bids. \n` +
                'Property developers benefit from a reduced number of laborers in contractors’ estimates and faster project times, thus saving significant amounts due to control of contractors\' efficiency'
        },
        sphere2: {
            title: 'EPC, Oil and Gas',
            description: 'Given that Oil and Gas and EPC industries have higher labor costs than typical construction contractors, the effects of inefficient labor are drastic, leading to multi-billion losses across the industry. SOLUT provides triple-digit ROI to EPC contractors, improving labor efficiency in welding, electrical and mechanical works, manual excavation, and every other labor-intensive operation',
        },
        sphere3: {
            title: 'Energy',
            description: 'Energy is one of the most critical sectors of the world’s economy. The importance of it places a heavy toll on the responsibility of every company working in it.  Every day, SOLUT helps energy companies be on time and have perfectly maintained infrastructure and equipment with less workforce than before. In addition, we make sure that maintenance and overhauls are done according to the technology controlling the correctness of laborers\' actions' ,
        } ,
        sphere4: {
            title: 'Retail and warehousing',
            description: 'Idle times of merchandisers and warehouse workers put a severe toll on the cost of operations. By measuring the active time of workers, SOLUT significantly reduces productivity losses and makes operations faster and more efficient',
        }
    },
    howItsWork: {
        heading: 'How it works?',
        description: 'SOLUT is very easy to use in every environment, it naturally fits daily routines and doesn’t interfere with laborers’ activities, comfort, safety, and privacy',
        description1: 'Every laborer gets the smartwatch at the beginning of the shift at the punch point',
        description2: 'Reports about every laborer\'s productive, idle, and walking times are generated',
        description3: ' Our methodology and daily measurement achieve actual productivity growth'
    },
    solveProblem: {
        heading: 'What problems do we solve?',
        description1: 'Long idle times during the shifts',
        description2: 'Identification of idle time reasons',
        description3: 'Violation of technical processes (sequence of actions)'
    },
    whatWeDo: {
        heading: 'What we do?',
        description1: 'Increase labor productivity by reducing the cost of productive hours and time losses',
        description2: 'Identify the reasons of idle time and resolve operational issues',
        description3: 'Implement fair pay practices for laborers and improve their wellbeing  '
    },
    otherSystems: {
        heading: 'Why other technologies \n do not deliver acceptable results?',
        description: 'SOLUT is the most efficient and reliable labor productivity monitoring system to date. Unlike other technologies and practices, we deliver precise measurements in any environment, for any trade, and without additional infrastructure or human involvement',
        system1: {
            title: 'Supervision',
            description: 'involves human factors and not sustainable'
        },
        system2: {
            title: 'Video recognition',
            description: 'linked to a specific location, requires a lot of maintenance and infrastructure'
        },
        system3: {
            title: 'Location tracking ',
            description: 'shows where the people are but doesn’t measure productivity, requires extensive infrastructure'
        }
    },
    realization: {
        heading: 'IMPLEMENTATION',
        description: 'Implementing SOLUT is effortless',
        step1_1: 'Contact us and sign a contract',
        step1_2: '',
        step2_1: 'We provide everything you need to run within 2 weeks',
        step2_2: '',
        step3_1: 'Get your profits in 3-6 months',
        step3_2: '',
        step3_3: '',

    },
    charts: {
        heading: 'What are you getting?',
        chart1: {
            title: 'Snapshot of the day ',
            description: 'Individual results and snapshot of the day - laborers and chargehands get the measurement of their results for the day, and can see every interval they worked, walked, or were idle giving an ultimate tool to understad work patterns, identify time losses, and quickly improve',
        },
        chart2: {
            title: 'Group comparison graph',
            description: 'Analytics for detailed dynamics - our analytics provides reports about groups dynamics, individual performance charts over period, results distribution by performance groups',
        },
        chart3: {
            title: 'Chart 3',
            description: 'Groups averages and rankings - simple pie charts is a powerful tool to rank groups, teams, divisions, and subcontractors by average productivity. For example, selecting subcontractors by ranking reduce costs and increase overall performance',
        }
    },
    payback: {
        heading: 'Return on investment',
        point1: {
            number: '250',
            description: 'return on investment',
        },
        point2: {
            number: '30',
            description: 'Productivity\nincrease',
        },
    },
    map: {
        heading: 'Worldwide \n presence',
        dotDescription1: '',
        dotDescription2: '',
        dotDescription3: '',
        point1: {
            number: '',
            description: '',
        }
    },
    results: {
        heading: 'Results \nof our clients',
        result1: {
            title: 'Клиент 1',
            description: 'описание'
        },
        result2: {
            title: 'Клиент 2',
            description: 'описание',
        },
        result3: {
            title: 'Клиент 3',
            description: 'описание',
        }
    },
    contacts: {
        heading: 'Contact us',
        card1: {
            name: 'mark\n Hani',
            lang: 'AR/EN/FR/RU',
            phone: '+966 11 510 3310',
            country: 'Business Development',
            email: 'marc@solut.ai',
            contactNumber: '966115103310',
            button: 'Contact'
        },
        card2: {
            name: 'Andrey\nChernykh',
            lang: 'EN/RU',
            phone: '+7 965 428-93-01',
            country: 'Business Development',
            email: 'cherand@solut.ru',
            contactNumber: '79654289301',
            button: 'Contact'
        },
        card3: {
            name: 'Pavel\nTatarintsev',
            lang: 'EN/RU',
            phone: '+971 55 948 49 38',
            country: 'Business Development',
            email: 'pavel@solut.ai',
            contactNumber: '971559484938',
            button: 'Contact'
        }
    },
    form: {
        heading: 'Have any questions?',
        description: 'Fill out the feedback form and our \nmanagers will contact you',
        form: {
            name: 'Name',
            contact: 'E-mail / or phone',
            question: 'Your question',
            button: 'Send'
        }
    }
}
